import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes, { InferProps } from 'prop-types';
import MediumCard from '@fuww/library/src/Cards/Medium';
import LinkAndAnchor from './LinkAndAnchor';
import getArticlesParameters from '../lib/getArticlesParameters';
import getProxiedImages from '../lib/getProxiedImages';
import { useSiteContext } from './SiteContext';
import ImageWithUrls from './ImageWithUrls';
import FormattedDate from './FormattedDate';
import getCategoryTitle from '../lib/getCategoryTitle';
import { ArticleQueryVariables } from '../lib/graphql/api/graphql';

const cardWithLinkPropertyTypes = {
  newsArticle: PropTypes.shape({
    category: PropTypes.shape({
      id: PropTypes.number,
    }),
    title: PropTypes.string.isRequired,
    path: PropTypes.string,
    summary: PropTypes.string,
    insertedAt: PropTypes.string,
    imageUrls: PropTypes.arrayOf(
      PropTypes.string,
    ).isRequired,
    labels: PropTypes.arrayOf(PropTypes.string),
    featured: PropTypes.bool,
    pressRelease: PropTypes.bool.isRequired,
    partnerContent: PropTypes.bool.isRequired,
  }).isRequired,
  variables: PropTypes.shape({}).isRequired,
  sizes: PropTypes.string,
  showCreatedAt: PropTypes.bool,
  indicateFeatured: PropTypes.bool,
  withPageMargins: PropTypes.bool,
};

type CardWithLinkProperties = InferProps<typeof cardWithLinkPropertyTypes> & {
  category: {
    id: number;
  } | null;
  variables: ArticleQueryVariables;
  sizes?: string;
  showCreatedAt?: boolean;
  indicateFeatured?: boolean;
  withPageMargins?: boolean;
  newsArticle: {
    path?: string;
  };
};

const CardWithLink = ({
  // eslint-disable-next-line max-len
  sizes = '(min-width: 1440px) 330px, (min-width: 1024px) 24vw, (min-width: 600px) 48vw, 100vw',
  showCreatedAt = true,
  indicateFeatured = true,
  newsArticle,
  variables,
  withPageMargins = false,
  ...rest
} : CardWithLinkProperties) => {
  const {
    category,
    title,
    path,
    summary,
    insertedAt,
    imageUrls: originalImageUrls,
    labels,
    featured,
    pressRelease,
    partnerContent,
  } = newsArticle;

  const intl = useIntl();
  const { hasLocalNews } = useSiteContext();

  const { imageUrls, srcSet } = getProxiedImages(variables, originalImageUrls);

  return (
    <LinkAndAnchor
      route="article"
      params={getArticlesParameters(newsArticle)}
      href={path}
      useRouter
    >
      <MediumCard
        image={(
          <ImageWithUrls
            sizes={sizes}
            imageUrls={imageUrls}
            alt={title}
            srcSet={srcSet}
            {...rest}
          />
        )}
        title={title}
        description={summary}
        insertedAt={showCreatedAt && <FormattedDate value={insertedAt} />}
        category={getCategoryTitle(category, hasLocalNews, intl)}
        labels={labels}
        isPressRelease={pressRelease}
        isPartnerContent={partnerContent}
        featured={indicateFeatured && Boolean(featured)}
        withPageMargins={withPageMargins}
      />
    </LinkAndAnchor>
  );
};

CardWithLink.propTypes = cardWithLinkPropertyTypes;

export default CardWithLink;
