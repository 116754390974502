import PropTypes from 'prop-types';

const propertyTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.node,
  category: PropTypes.string,
  insertedAt: PropTypes.node,
  labels: PropTypes.arrayOf(PropTypes.string),
  withPageMargins: PropTypes.bool,
  showLoader: PropTypes.bool,
};

export default propertyTypes;
