import React from 'react';
import PropTypes from 'prop-types';
import { GridCell } from '@fuww/library/src/Grid';
import NewsCardWithLink from '../../CardWithLink';
import loadMore from '../../../lib/queries/loadMore';
import loadMoreByPath from '../../../lib/queries/loadMoreByPath';
import LoadMore from '../../LoadMore';

const ArticleFeed = ({
  variables,
  fetchMore,
  field,
  articlesField,
  data,
  articles,
  loading,
  preloadFirst,
}) => (
  <>
    {articles.edges.map(({ node: newsArticle }, index) => {
      const isFirstImage = index === 0;
      const fetchPriority = (preloadFirst && isFirstImage) ? 'high' : 'low';

      return (
        <GridCell
          sm={6}
          lg={3}
          key={newsArticle.id}
          padding="16px"
          align="top"
        >
          <NewsCardWithLink
            newsArticle={newsArticle}
            variables={variables}
            preload={preloadFirst && isFirstImage}
            finalFetchpriority={fetchPriority}
            placeholderFetchpriority={fetchPriority}
            withPageMargins
          />
        </GridCell>
      );
    })}
    <GridCell padding="16px">
      {articlesField ? (
        <LoadMore
          loading={loading}
          hasNextPage={data[field][articlesField].pageInfo.hasNextPage}
          fetchMore={
            () => loadMoreByPath(
              { variables },
            )(data, field, articlesField, fetchMore)
          }
        />
      ) : (
        <LoadMore
          loading={loading}
          hasNextPage={data[field].pageInfo.hasNextPage}
          fetchMore={
            () => loadMore({ variables })(data, field, fetchMore)
          }
        />
      )}
    </GridCell>
  </>
);

ArticleFeed.propTypes = {
  articles: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
  fetchMore: PropTypes.func,
  variables: PropTypes.shape({}),
  data: PropTypes.shape({}),
  field: PropTypes.string,
  articlesField: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  preloadFirst: PropTypes.bool,
};

ArticleFeed.defaultProps = {
  articles: null,
  fetchMore: undefined,
  variables: undefined,
  data: null,
  field: null,
  articlesField: null,
  preloadFirst: false,
};

export default ArticleFeed;
