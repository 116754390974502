const loadMoreByPath = ({ variables }) => (
  data,
  queryName,
  fieldName,
  fetchMore,
) => {
  fetchMore({
    variables: {
      after: data[queryName][fieldName].pageInfo.endCursor,
      ...variables,
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      const { __typename } = fetchMoreResult[queryName];
      const {
        edges: newsEdges,
        pageInfo,
      } = fetchMoreResult[queryName][fieldName];
      const {
        __typename: connectionTypeName,
        edges,
      } = previousResult[queryName][fieldName];

      return {
        [queryName]: {
          ...previousResult[queryName],
          __typename,
          [fieldName]: {
            __typename: connectionTypeName,
            edges: [...edges, ...newsEdges],
            pageInfo,
          },
        },
      };
    },
  });
};

export default loadMoreByPath;
