import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import {
  CardOverline, CardDate, CardBodyText, CardOverlineSuffix,
  CardTextContainer, CardContainer, FullWidthImageContainer,
} from '../Card';
import Headline5 from '../Typography/Heading/Headline5';
import Labels from '../Card/Labels';
import cardPropertyTypes from './propertyTypes';

const StyledHeadline5 = styled(Headline5)<
{
  hasIcon: boolean;
}
>`
  && {
    margin: 0 0 8px;
    ${(properties : { hasIcon: boolean }) => properties.hasIcon && `
      display: flex;
      justify-content: space-between;
    `
}
  }
`;

const mediumCardPropertyTypes = {
  ...cardPropertyTypes,
  children: PropTypes.node,
  icon: PropTypes.node,
  featured: PropTypes.bool,
  logoLetterbox: PropTypes.bool,
  isPressRelease: PropTypes.bool,
  isPartnerContent: PropTypes.bool,
};

type MediumCardProperties = InferProps<typeof mediumCardPropertyTypes> & {
  featured?: boolean;
  withPageMargins?: boolean;
  logoLetterbox?: boolean;
  isPressRelease?: boolean;
  isPartnerContent?: boolean;
};

const MediumCard = ({
  title,
  category,
  insertedAt,
  image,
  description,
  labels = [],
  children,
  icon,
  featured = false,
  withPageMargins = false,
  logoLetterbox = false,
  isPressRelease = false,
  isPartnerContent = false,
} : MediumCardProperties) => (
  <CardContainer className="card-container">
    {image && (
    <FullWidthImageContainer
      featured={featured}
      withPageMargins={withPageMargins}
      logoLetterbox={logoLetterbox}
    >
      {image}
    </FullWidthImageContainer>
    )}
    <CardTextContainer>
      { category && (
        <CardOverline>
          {category}
          {isPressRelease && (
            <CardOverlineSuffix>
              Press Release
            </CardOverlineSuffix>
          )}
          {isPartnerContent && (
            <CardOverlineSuffix>
              Partner Content
            </CardOverlineSuffix>
          )}
          <Labels
            labels={labels}
            featured={featured}
          />
        </CardOverline>
      )}
      <StyledHeadline5
        tag="h2"
        hasIcon={Boolean(icon)}
      >
        {title}
        {icon}
      </StyledHeadline5>
      {
        description && (
          <CardBodyText>
            {description}
          </CardBodyText>
        )
      }
      {insertedAt && <CardDate>{insertedAt}</CardDate>}
      { children }
    </CardTextContainer>
  </CardContainer>
);

MediumCard.propTypes = mediumCardPropertyTypes;

export default MediumCard;
